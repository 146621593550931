import { ReactElement, memo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { ServiceProps } from '../../constants';
import { useNavigate } from '../../hooks';

interface ServiceComponentProps {
    service: ServiceProps;
}

function Service({ service }: ServiceComponentProps): ReactElement {
    const { path } = useNavigate();
    return (
        <Grid item xs={12} sm={6} md={4}>
            <Paper
                component='div'
                sx={{ background: '#f5f5f5', height: '100%' }}
            >
                <Stack
                    component='section'
                    alignItems='center'
                    justifyContent='flex-start'
                    textAlign='center'
                    p='1.5rem'
                >
                    <Stack maxWidth='5.5rem' mt='-4rem' mb='1rem'>
                        <img src={service.Icon} alt={service.Icon} />
                    </Stack>
                    <Typography
                        component='h5'
                        variant='h5'
                        fontWeight={500}
                        textAlign='center'
                    >
                        {service.title}
                    </Typography>
                    <Typography
                        component='p'
                        variant='body2'
                        textAlign='center'
                        mt='0.5rem'
                    >
                        {path === '/'
                            ? service.shortDescription
                            : service.description}
                    </Typography>
                </Stack>
            </Paper>
        </Grid>
    );
}
export default memo(Service);
