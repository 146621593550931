import { ReactElement, memo } from "react";
import Container from "@mui/material/Container";
import { ILink, Navbar, Services } from "../components";
import { ServicesConstant } from "../constants";

function ServicesPage(): ReactElement {
  const services = [
    ServicesConstant.Adult,
    ServicesConstant.Child,
    ServicesConstant.Couples,
    ServicesConstant.Family,
    ServicesConstant.Individual,
    ServicesConstant.Group,
  ];

  const links: Array<ILink> = [
    { label: "Home", to: "/" },
    { label: "About", to: "/About" },
    { label: "Fees", to: "/About#Fees" },
  ];

  return (
    <Navbar links={links}>
      <Container component="main" maxWidth="lg">
        <Services services={services} />
      </Container>
    </Navbar>
  );
}

export default memo(ServicesPage);