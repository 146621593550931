import { ReactElement, memo } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { ContactDetails, ContactForm } from '..';
import { useDimensions } from '../../hooks';

function Footer(): ReactElement {
    const { width } = useDimensions();
    return (
        <Box
            component='footer'
            minWidth='100vw'
            maxWidth='100vw'
            mt='5rem'
            pt='4rem'
            pb={width > 858 ? 2 : 10}
            sx={{ background: '#e0e0e0' }}
            id='contact'
        >
            <Container component='main' maxWidth='lg'>
                <Stack>
                    <Stack
                        component='div'
                        direction={{ xs: 'column-reverse', md: 'row' }}
                        spacing={5}
                        alignItems={{ xs: 'flex-start', md: 'center' }}
                        justifyContent='space-between'
                        mb='1.5rem'
                        sx={{ width: '100%' }}
                    >
                        <ContactDetails />
                        <ContactForm />
                    </Stack>
                    <Typography variant='caption' component='caption'>
                        &copy; Deidré du Toit {new Date().getFullYear()} |
                        developed by Vermelion
                    </Typography>
                </Stack>
            </Container>
        </Box>
    );
}
export default memo(Footer);
