import { ReactElement, memo, ReactNode } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Logo from "../../assets/images/Logo.png";
import LogoName from "../../assets/images/Logo_Name.png";
import { NavAnchor, NavLink } from "./Navbar.Style";
import Footer from "../Footer";
import Box from "@mui/material/Box";
import { useDimensions } from "../../hooks";
import NavbarDrawer from "./Navbar.Drawer";

export interface ILink {
  to?: string;
  href?: string;
  label: string;
}

interface NavbarProps {
  links: ILink[];
  children?: ReactNode;
}

function Navbar({ links, children }: NavbarProps): ReactElement {
  const { width } = useDimensions();
  return (
    <>
      <Box
        component="nav"
        bgcolor="#fefefe"
        position="fixed"
        top={width > 858 ? 0 : "auto"}
        bottom={width > 858 ? "auto" : 0}
        left={0}
        right={0}
        zIndex={10}
        py={3}
      >
        <Container maxWidth="lg">
          <Stack
            component="nav"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
            maxWidth="100%"
            py={0.5}
          >
            {/* <Stack
                            component='a'
                            href='#'
                            width={width > 858 ? '15rem' : '5rem'}
                            maxWidth={width > 858 ? '15rem' : '5rem'}
                            sx={{ cursor: 'pointer' }}
                        >
                            <img
                                src={width > 858 ? LogoName : Logo}
                                alt={width > 858 ? LogoName : Logo}
                            />
                        </Stack> */}
            {/* {width > 500 ? ( */}
            <Stack component="div" direction="row" spacing={{ xs: 2.5, sm: 5 }}>
              {links.map((link) =>
                link.href ? (
                  <NavAnchor href={link.href}>{link.label}</NavAnchor>
                ) : (
                  link.to && <NavLink to={link.to}>{link.label}</NavLink>
                )
              )}
            </Stack>
            {/* ) : (
                            <NavbarDrawer links={links} />
                        )} */}
          </Stack>
        </Container>
      </Box>
      {children}
      <Footer />
    </>
  );
}
export default memo(Navbar);