import { ReactElement, memo } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ILink, Navbar } from "../components";

function FeesPage(): ReactElement {
  const links: Array<ILink> = [
    { label: "Home", to: "/" },
    { label: "Services", to: "/Services" },
    { label: "About", to: "/About" },
    { label: "Contact", href: "#contact" },
  ];
  return (
    <Navbar links={links}>
      <Stack mt={18}>
        <Container maxWidth="lg">
          <Typography>
            Clients with medical aids will be charged according to medical aid
            rates. Once medical aid rates are depleted, application for
            Prescribed Minimum Benefits (PMBs) can be made, but if rejected, it
            remains the client’s responsibility to pay for the sessions in full.
            <br />
            <br />
            Clients who prefer to pay privately will be charged R1050 per
            therapy session (50-55 minutes). Couples' therapy sessions are R1250
            per session (50-55 minutes).
            <br />
            <br />
            Family therapy sessions are R1450 per session (50-55 minutes).
            <br />
            <br />
            Group therapy session fees will be discussed according to a fee
            structure and the needs of the clients. Contact me directly for
            further information.
            <br />
            <br />
            Note: Payment ultimately remains the responsibility of the
            individuals utilising the services. Regarding individuals younger
            than 18 years of age, the consenting caregiver(s) or guardian(s) are
            responsible for the settlement of any outstanding payments. Sessions
            missed, cancelled, or changed with less than 24 hours’ notice will
            be charged as a full session fee and will not be claimed from
            medical aids. If individuals are more than 20 minutes late for a
            session and have not provided a sufficient reason to the therapist,
            the session will be considered as missed and charged accordingly.
            <br />
            <br />
            This is subject to annual increases which will be communicated
            accordingly.
          </Typography>
          {/* <Fees /> */}
        </Container>
      </Stack>
    </Navbar>
  );
}
export default memo(FeesPage);